/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import type { FC } from 'react'
import Script from 'next/script'
import { isUndefined } from '../../../utils/object'

const scriptCode = (gtmId: string) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`

const enableGtagApi = `
 window.dataLayer = window.dataLayer || [];
`
interface Props {
  id?: string
  isEnable?: boolean
}

const GtmScript: FC<Props> = ({ id, isEnable }) => {
  if (!isEnable || isUndefined(id)) {
    return null
  }

  return (
    <>
      <Script
        id="google-tag-manager"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{ __html: scriptCode(id) }}
      />
      <Script id="enable-gtag-api" strategy="beforeInteractive" dangerouslySetInnerHTML={{ __html: enableGtagApi }} />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </>
  )
}

export default GtmScript
