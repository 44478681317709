import {
  ArticleJsonLdProps,
  JobPostingJsonLdProps,
  SiteLinksSearchBoxJsonLdProps,
  SocialProfileJsonLdProps,
  WebPageJsonLdProps,
} from 'next-seo'
import { ArticleJsonLd, JobPostingJsonLd, SiteLinksSearchBoxJsonLd, SocialProfileJsonLd, WebPageJsonLd } from 'next-seo'

export type JsonLdProps =
  | ArticleJsonLdProps
  | JobPostingJsonLdProps
  | SocialProfileJsonLdProps
  | SiteLinksSearchBoxJsonLdProps
  | WebPageJsonLdProps

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonLdComponent = (type: string): any => {
  switch (type) {
    case 'page':
    case 'taxonomy.category':
    case 'taxonomy.tag':
    case 'destination':
    case 'article':
    case 'gallery':
      return ArticleJsonLd
    case 'job':
      return JobPostingJsonLd
    case 'testimonial':
    case 'author':
      return SocialProfileJsonLd
    case 'search':
      return SiteLinksSearchBoxJsonLd
    default:
      return WebPageJsonLd
  }
}
