import { FC, Fragment } from 'react'
import cx from 'clsx'
import { BreadcrumbJsonLd } from 'next-seo'
import { ItemListElements } from 'next-seo/lib/types'
import { CaretRight, House } from 'phosphor-react-sc'
import { getSiteUrl } from '@/lib/routes'
import { fetchSanityClient } from '@/lib/sanity'
import { PageType, TBreadCrumb } from '@/types/site.interface'
import SafeLink from '@ignition/library/components/atoms/safe-link'
import logger from '@ignition/library/lib/logger'
import { ensureLeadingSlash } from '@ignition/library/utils/urls'

const breadcrumbLdJson = (crumbs: BreadcrumbItem[], hostName: string): ItemListElements[] => {
  return crumbs.map((crumb, i) => {
    return {
      position: i + 1,
      name: crumb.breadcrumbTitle || crumb.title || 'Home',
      item: `${getSiteUrl(hostName)}${crumb.slug}`,
    }
  })
}
interface BreadcrumbItem {
  title: string
  slug: string
  breadcrumbTitle?: string
}

interface BreadcrumbItemProps extends BreadcrumbItem {
  current?: boolean
  first?: boolean
}

export interface BreadcrumbsProps {
  isHome?: boolean
  slug: string
  breadcrumb?: TBreadCrumb
  hostName: string
  type: PageType
}

const classes = {
  container:
    'breadcrumb relative max-w-7xl pt-5 pb-1 sm:py-4 md:py-4 mx-auto max-w-sm px-4 sm:max-w-lg sm:px-6 lg:max-w-3xl lg:px-8',
  itemAnchor: 'text-sm',
  itemAnchorCurrent: 'font-bold text-primary',
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ slug, breadcrumbTitle, title, first, current }) => (
  <li className="breadcrumb-item">
    <div className="flex items-center">
      {!first && <CaretRight weight="bold" className="mx-1 self-center text-primary" />}

      <SafeLink
        id={`breadcrumb-${title}`}
        href={slug}
        className={cx(classes.itemAnchor, current && classes.itemAnchorCurrent)}
      >
        {first ? <House weight="bold" className="mx-1 self-center text-primary" /> : breadcrumbTitle || title}
      </SafeLink>
    </div>
  </li>
)

const BreadCrumbNav = ({ children }) => (
  <nav className="flex" aria-label="Breadcrumb">
    <div>
      <div className="flex flex-row">
        <div className="flex-grow">
          <ol className="flex items-center flex-row flex-wrap">{children}</ol>
        </div>
      </div>
    </div>
  </nav>
)
const pathParams = (slug: string): string[] => {
  const crumbs = slug.split('/').slice(1)
  return crumbs.map((crumb, i) => {
    const path = crumbs.slice(0, i + 1).join('/')
    return ensureLeadingSlash(path)
  })
}

const breadcrumbQuery = `
 *[ slug.fullPath in $path && !(_id in path("drafts.**"))] {
    "slug": slug.fullPath,
    "title": coalesce(title, name, author),
    "breadcrumbTitle": breadcrumb.title,
}`

const getBreadcrumb = async (slug: string): Promise<BreadcrumbItem[] | undefined> => {
  const path: string[] = pathParams(slug)

  if (!path.length || path.length <= 1) return []
  try {
    const crumbs: BreadcrumbItem[] = await fetchSanityClient(breadcrumbQuery, { path }, { tags: [slug] })
    return crumbs.sort((a, b) => path.indexOf(a.slug) - path.indexOf(b.slug))
  } catch (err) {
    logger.error('Failed to fetch breadCrumb', err)
  }
}

const Breadcrumb = async ({ breadcrumb, slug, isHome, type, hostName }: BreadcrumbsProps) => {
  if (breadcrumb?.isDisabled || isHome || type !== 'entry') {
    return null
  }
  const crumbs = await getBreadcrumb(slug)
  if (!crumbs?.length) return null

  return (
    <div className={classes.container}>
      <BreadcrumbJsonLd useAppDir itemListElements={breadcrumbLdJson(crumbs, hostName)} />
      <BreadCrumbNav>
        {crumbs.map((crumb, index) => (
          <Fragment key={`breadcrumb-${crumb.slug}`}>
            {index === 0 && <BreadcrumbItem slug={'/'} title="Home" first={true} />}
            <BreadcrumbItem {...crumb} current={index === crumbs.length - 1} />
          </Fragment>
        ))}
      </BreadCrumbNav>
    </div>
  )
}

export default Breadcrumb
