import React, { FC } from 'react'
import { X } from 'phosphor-react-sc'
import { ConditionalWrapper } from '@/components/atoms/conditional-wrapper'
import { Link } from '@/types/site.interface'
import SafeLink from '@ignition/library/components/atoms/safe-link'

interface Props {
  text?: string
  link?: Link
}

const CloseButton = ({ onClick }) => (
  <div className="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
    <button
      onClick={onClick}
      type="button"
      className="flex rounded-md p-2 hover:bg-white hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-white"
    >
      <span className="sr-only">Dismiss</span>
      <X className="h-6 w-6 text-white" aria-hidden="true" />
    </button>
  </div>
)

const PromoBar: FC<Props> = React.memo(({ text, link }) => {
  return (
    <div className="relative bg-indigo-600">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:px-16 sm:text-center">
          <p className="font-medium text-white">
            <span className="block sm:ml-2 sm:inline-block">
              <ConditionalWrapper
                condition={!!link}
                wrapper={(children) => (
                  <SafeLink className="text-white underlinek" href={link?.slug}>
                    {children} <span aria-hidden="true"> &rarr;</span>
                  </SafeLink>
                )}
              >
                <span className="inline">{text}</span>
              </ConditionalWrapper>
            </span>
          </p>
          <CloseButton onClick={() => {}} />
        </div>
      </div>
    </div>
  )
})

export default PromoBar
