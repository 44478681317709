import React, { FC, ReactNode } from 'react'
import GenericHeader from '@/components/navigation/generic-header'
import { PageProps, PageTypeProps, SiteProps } from '@/types/site.interface'
import GtmScript from '@ignition/library/components/atoms/tracker/gtm-script'
import HeadSeo from '@ignition/library/src/lib/seo/head-seo'
import Breadcrumb from './navigation/breadcrumb'
import { GenericFooter } from './navigation/generic-footer'
import PromoBar from './promo-bar'

interface Props extends PageTypeProps {
  children: ReactNode
  site: SiteProps
  page: PageProps
}

const Layout: FC<Props> = ({ page, site = {}, seo = {}, ldJson, children }) => {
  const { promo } = site.header
  const { socialLinks, siteLogo, gtm } = site
  return (
    <>
      <HeadSeo seo={seo} ldJson={ldJson} type={page?.entryType || page?.type} />
      <GtmScript id={gtm?.id} isEnable={gtm?.isEnable} />
      <div>
        {/* <Header data={site.header} /> */}
        {promo?.enabled && <PromoBar text={promo.text} link={promo.link} />}
        <GenericHeader
          menuDesktopPrimary={site.header.menuDesktopPrimary}
          menuDesktopSecondary={site.header.menuDesktopSecondary}
          menuMobilePrimary={site.header.menuMobilePrimary}
          menuMobileSecondary={site.header.menuMobileSecondary}
          navButtons={site.header.navButtons}
          socialLinks={socialLinks}
          siteLogo={siteLogo}
          siteName={site.title}
        />
        <main id="content">
          {page.type === 'entry' && <Breadcrumb {...page} hostName={site.hostName || ''} />}
          {children}
        </main>
        <GenericFooter
          copyright={site.footer?.copyright}
          slogan={site.footer?.slogan}
          menuFooter={site.footer?.menuFooter}
          socialLinks={socialLinks}
          siteLogo={siteLogo}
          siteName={site.title}
        />
      </div>
    </>
  )
}

export default Layout
