import { FC } from 'react'
import { resolveURL } from '@/lib/routes'
import { ImageAsset } from '@/types/site.interface'
import { MenuProps } from '../../components/navigation/menu.interface'
import { SocialLinks } from '../elements/social-links'
import { SocialLink } from '../elements/social-links.interface'
import { SiteLogo } from '../molecules/site-logo'
import { NavigationItem } from './navigation-item'
import { NavigationList } from './navigation-list'

interface GenericFooterProps {
  slogan?: string
  copyright?: string
  menuFooter?: MenuProps
  socialLinks?: SocialLink[]
  siteLogo?: ImageAsset
  siteName?: string
}
export const GenericFooter: FC<GenericFooterProps> = ({
  slogan,
  menuFooter,
  socialLinks,
  copyright,
  siteLogo,
  siteName,
}) => {
  return (
    <footer className="relative bg-footer-background" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="relative mx-auto flex-col max-w-3xl items-center justify-between px-4 sm:px-6 pt-12 lg:pt-16">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            {siteLogo && <SiteLogo siteLogo={siteLogo} siteName={siteName} />}
            <p className="text-base text-footer-text">{slogan}</p>
            <div className="flex space-x-6">{socialLinks && <SocialLinks socialLinks={socialLinks} />}</div>
          </div>
          <div className="mt-12 sm:col-span-2 xl:mt-0  text-footer-text text-left">
            {menuFooter && menuFooter.items?.length && (
              <NavigationList>
                {menuFooter.items.map((item, index) => {
                  return <NavigationItem key={`k-${index}-${item.id}`} title={item.title} href={resolveURL(item)} />
                })}
              </NavigationList>
            )}
          </div>
        </div>
        <div className="mt-12 border-t border-footer-text-copyright/50 py-8">
          <p className="text-sm text-footer-text-copyright xl:text-center">{copyright}</p>
        </div>
      </div>
    </footer>
  )
}
